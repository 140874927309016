<template>
  <h6 class="text-h5 font-weight-bold white--text text--lighten-1 d-flex align-center">
    <div class="rounded-pill purple lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3">
      <v-icon small color="white">{{ titleIcon }}</v-icon>
    </div>
    {{ title }}
  </h6>
</template>
<script>
export default {
  props: ["title", "titleIcon"],
};
</script>
